

import { useState } from 'react';
import './numberControl.scss';

interface NumberControlProps {
    price: string;
    hideButtons: boolean;
    priceIndex: number;
    onChange: (priceIndex: number, change: number) => void;
}

export function NumberControl(props: NumberControlProps) {
    const [animate, setAnimate] = useState(false);

    const [lockInDigit, setLockInDigit] = useState(getDidgit());

    function getDidgit() {
        //var priceIndex = props.price.length - props.priceIndex;
        var priceArray: Array<string> = props.price.split("");
        var priceChar = priceArray.reverse()[props.priceIndex]
        return priceChar;
    }

    function onClick(change: number) {
        
        setAnimate(true);

        setTimeout(() => {
            setLockInDigit(getDidgit());
        },50)
        

        setTimeout(() => {
            props.onChange(props.priceIndex, change);
        },150)
        

        setTimeout(() => {
            setAnimate(false);
            
        },300)

    }

    return (
        <div className='number-container'>
            {!props.hideButtons && <div className='action' onClick={() => onClick(1)}>+</div>}
            <div className='number'>                
                <div className={animate ? 'number-animation' : 'number-animation-top'}></div>
                <div className='digit'>{lockInDigit}</div>
                <div className='number-divider'></div>
            </div>
            <div className={animate ? 'number animate' : 'number'}>
                <div className={animate ? 'number-animation animate' : 'number-animation-top'}></div>
                <div className='digit'>{getDidgit()}</div>                
                <div className='number-divider'></div>
            </div>
            {!props.hideButtons && <div className='action bottom' onClick={() => onClick(-1)}>-</div>}
        </div>
    )
}
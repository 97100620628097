import { PropsWithChildren } from 'react';
import './button.css';

export type ButtonVariant = 'Primary' | 'Cancel';

interface ButtonProps {
    variant?: ButtonVariant;
    onClick?: () => void;
}

export function Button(props: PropsWithChildren<ButtonProps>) {
    return (
        <button
            className={props.variant === 'Cancel' ? 'gg-button cancel' : 'gg-button'}
            onClick={() => props.onClick && props.onClick()}
        >
            {props.children}
        </button>
    )
}
import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { ISourceOptions } from "tsparticles-engine";
import { Engine } from "tsparticles-engine/types/engine";
import particlesOptions from "../particlesPoop.json";

interface AnimatedBackgroundProps {
    options: ISourceOptions;
}

// https://particles.js.org/samples/index.html
// https://particles.js.org/docs/interfaces/Options_Interfaces_IOptions.IOptions.html

export function AnimatedBackground(props: AnimatedBackgroundProps) {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, [props.options]);

    return (
        <Particles
            options={props.options}
            init={particlesInit} />
    )
}
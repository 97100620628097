import React from "react";

import './bushesBackground.scss';

export function BushesBackground() {
    return (
        <div className='gg-gradient'>
            {/* <img alt='left bush' className='left-bush' src='/left-bush.png' />
            <img alt='right bush' className='right-bush' src='/right-bush.png' /> */}
        </div>
    )
}
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/button";

import './landingPage.scss';

export function Home() {
	return (<>
		<div className='landing-page'>
			<div className='monkey-container'>
				<img alt='logo' className='monkey' width={'100%'} src='https://storage.googleapis.com/good-guesser.appspot.com/public/monkey-trans-small.png' />
				<div className='how-to-play-container'>
					<h3 className='how-to-play-title'>How to Play</h3>
					<p className='how-to-play-body'>Guess the list price of the displayed item (good) to the nearest dollar in as few guesses as possible. For each guess you will be told if the actual price is higher or lower.</p>

					<div style={{textAlign:'center', marginBottom: '10px'}} className='how-to-play-body'><strong>Price Hints</strong></div>
					<table className='guesses-key-table'>
						<tbody>
							<tr>
								<td style={{textAlign:'center'}}>🔻🔻🔻</td>
								<td>Significantly lower</td>
							</tr>
							<tr>
								<td style={{textAlign:'center'}}>🔺🔺</td>
								<td>Higher</td>
							</tr>
							<tr>
								<td style={{textAlign:'center'}}>🔻</td>
								<td>Slightly lower</td>
							</tr>
						</tbody>
					</table>
					<p style={{textAlign:'center', marginTop: '10px'}} className='how-to-play-body'>Prices are the $USD list price. <strong>Good luck!</strong></p>
				</div>
				<Link to={'/quiz'}><Button>Start</Button></Link>
			</div>
		</div>
	</>
	)
}
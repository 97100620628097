import React, { useState } from 'react';
import { Quiz } from './view/quiz';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { Routes, Route } from "react-router-dom";
import { Home } from './view/landingPage';
import { BushesBackground } from './components/bushesBackground';
import { AnimatedBackground } from './components/animatedBackground';

import particlesBananaOptions from "./particlesBananas.json";
import particlesBoxesOptions from "./particlesBoxes.json";
import particlesPoopOptions from "./particlesPoop.json";
import { ISourceOptions } from 'tsparticles-engine';

// Add converter https://firebase.google.com/docs/firestore/query-data/get-data
//  yarn run firebase deploy

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABAASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_MEASUREMENT_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABAASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID
};

export const fbApp = initializeApp(firebaseConfig);
export const fbAnalytics = getAnalytics(fbApp);
export const fbDb = getFirestore(fbApp);

export type ParticleTypes = "Boxes" | "Bananas" | "Poop";
type IParticleContext = {
  type: ParticleTypes,
  changeType: (newType: ParticleTypes) => void;
}
export const ParticleContext = React.createContext<IParticleContext>({
  type: 'Boxes',
  changeType: () => { }
});

//https://medium.com/geekculture/firebase-auth-with-react-and-typescript-abeebcd7940a
// https://particles.js.org/docs/interfaces/Options_Interfaces_IOptions.IOptions.html
function App() {
  const [particleType, setParticleType] = useState<ParticleTypes>('Boxes')

  function getParticleSettings() {
    if (particleType === 'Bananas') {
      return particlesBananaOptions as ISourceOptions;
    }
    if (particleType === 'Poop') {
      return particlesPoopOptions as ISourceOptions;
    }
    return particlesBoxesOptions as ISourceOptions;
  }

  return (
    <ParticleContext.Provider value={{
      type: 'Boxes',
      changeType: (newType: ParticleTypes) => setParticleType(newType)
    }}>
      <BushesBackground />
      <AnimatedBackground options={getParticleSettings()} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="quiz" element={<Quiz />} />
      </Routes>
    </ParticleContext.Provider>
  );
}

export default App;

import React, { useContext, useState } from "react";


import './quiz.scss';
import { NumberControl } from "../components/numberControl";
import { Button } from "../components/button";
import { useNavigate } from "react-router";
import { logEvent } from "firebase/analytics";
import { fbAnalytics, ParticleContext } from "../App";
import { ShareButton } from "../components/shareButton";

interface Guess {
	code: string;
	codeHint: string;
	higherLower: string;
	guess: string;
}

// https://affiliate-program.amazon.com/p/ideahub/home
// Best sellers https://affiliate-program.amazon.com/p/ideahub/home?contentTypes=%5B%5D&categories=%5B%5D&sortBy=DEFAULT&ratingFilter=DEFAULT&featuredContentType=best-sellers&queryString=

export function Quiz() {
	const { changeType } = useContext(ParticleContext);
	const maxGuesses = 10;
	const navigate = useNavigate();
	const [expandHeading, setExpandHeading] = useState(false);
	const [price, setPrice] = useState('0123');
	const [guesses, setGuesses] = useState<Guess[]>([]);
	const [latestedGuess, setLatestedGuess] = useState(0);
	const [animateLower, setAnimateLower] = useState(0);
	const [animateHigher, setAnimateHigher] = useState(0);
	const [shareText, setShareText] = useState('');
	const [shareResult, setShareResult] = useState('');
	const [failed, setFailed] = useState(false);

	const puzzelDay = 8;
	const productSalePrice = 349.99;
	const productRRPPrice = 449.99;	
	const productRRPRoundedPrice = 450;
	const productTitle = "SAMSUNG Galaxy A53";
	const productDescription = 'SAMSUNG Galaxy A53 5G A Series Cell Phone, Factory Unlocked Android Smartphone, 128GB, 6.5” FHD Super AMOLED Screen, Long Battery Life, US Version, Black';
	const productImage = "https://m.media-amazon.com/images/I/71HN4P-pd5L._AC_SL1223_.jpg";
	const productBuyNowLink = "https://amzn.to/3o5QaOE";

	function onPriceChange(priceIndex: number, step: number) {
		setPrice(oldPrice => {

			let oldPriceStringArray: Array<string> = oldPrice.split("").reverse();

			var valueString = oldPriceStringArray[priceIndex];
			var valueNumber = Number(valueString);

			var newValueNumber = valueNumber + step;
			if (newValueNumber < 0) {
				newValueNumber = 9;
			}
			if (newValueNumber > 9) {
				newValueNumber = 0;
			}

			oldPriceStringArray[priceIndex] = (newValueNumber + '')


			return oldPriceStringArray.reverse().join('');
		});
	}

	function checkPrice() {
		var num = Number(price);

		if ((guesses.length + 1) === maxGuesses && num !== productRRPRoundedPrice) {
			// Failed!
			setGuesses(existing => [{
				code: '❌',
				codeHint: '❌',
				higherLower: 'Failed!',
				guess: num + ''
			}, ...existing]);
			setFailed(true);
			changeType('Poop');

			return;
		}

		var diff = Math.abs(productRRPRoundedPrice - num);
		var diffPercent = diff / productRRPRoundedPrice * 100.0;
		var iconCount = 1;
		if (diffPercent > 50) {
			iconCount = 3;
		} else if (diffPercent > 20) {
			iconCount = 2;
		}

		if (num < productRRPRoundedPrice) {
			setAnimateHigher(iconCount);
			setTimeout(() => { setAnimateHigher(0) }, 1000);
		} else if (num > productRRPRoundedPrice) {
			setAnimateLower(iconCount);
			setTimeout(() => { setAnimateLower(0) }, 1000);
		}

		// 🔼🔻🔺🟢✅
		setLatestedGuess(num);
		if (num === productRRPRoundedPrice) {
			logEvent(fbAnalytics, 'post_score', { score: guesses.length + 1 });

			setGuesses(existing => [{
				code: '✅',
				codeHint: '✅',
				higherLower: 'Correct!',
				guess: num + ''
			}, ...existing]);

			changeType('Bananas');
		} else {
			setGuesses(existing => [{
				code: num > productRRPRoundedPrice ? '🔻' : '🔺',
				codeHint: num > productRRPRoundedPrice ? '🔻🔻🔻'.substring(0, iconCount * 2) : '🔺🔺🔺'.substring(0, iconCount * 2),
				higherLower: num > productRRPRoundedPrice ? 'Lower!' : 'Higher!',
				guess: num + ''
			}, ...existing]);
		}
	}

	function getShareText() {
		var reversedGuesses = [...guesses].reverse();
		var messageStart = `#GoodGuesser ${puzzelDay.toString().padStart(3, '0')} ${reversedGuesses.length}/${maxGuesses}`;
		var shareMessage = `${messageStart}\n${reversedGuesses.map(x => x.code).join('')}\nhttps://goodguesser.com`;
		return shareMessage;
	}

	function getShareBody() {
		var reversedGuesses = [...guesses].reverse();
		var messageStart = `#GoodGuesser ${puzzelDay.toString().padStart(3, '0')} ${reversedGuesses.length}/${maxGuesses}`;
		return <div>{messageStart}<br />{reversedGuesses.map(x => x.code).join('')}<br />https://goodguesser.com</div>;
	}

	function buyOnAmazon() {
		logEvent(fbAnalytics, 'view_item', {
			currency: 'USD', value: productRRPPrice, items: [{
				item_name: productTitle,
				price: productRRPPrice
			}]
		});
	}

	return (
		<section className='quiz-container'>
			<div className='gg-title-container'>
				<div style={{ flexBasis: '100px', flexShrink: 0 }}>
					<img alt='logo' src='/monkey-head.png' />
				</div>
				<a href='/' onClick={() => navigate('/')} style={{ flexGrow: 1 }}>
					<img alt='good guesser' src='/good-guesser.png' />
				</a>
			</div>

			<h2 className='item-title'>{productTitle}</h2>

			<div className='item-desc-container' onClick={() => setExpandHeading(!expandHeading)}>
				<p className={expandHeading ? 'item-desc' : 'item-desc hidden'}>{productDescription}</p>
				<div className={expandHeading ? "chevron open" : "chevron"}></div>
			</div>

			<div className='item-img-container'>
				<img className='item-img' alt='product' src={productImage} />
			</div>

			{latestedGuess !== productRRPRoundedPrice && !failed && <div className='price-container'>
				<div className='dollar'>$</div>
				<NumberControl price={price} onChange={onPriceChange} priceIndex={3} hideButtons={latestedGuess === productRRPRoundedPrice} />
				<NumberControl price={price} onChange={onPriceChange} priceIndex={2} hideButtons={latestedGuess === productRRPRoundedPrice} />
				<NumberControl price={price} onChange={onPriceChange} priceIndex={1} hideButtons={latestedGuess === productRRPRoundedPrice} />
				<NumberControl price={price} onChange={onPriceChange} priceIndex={0} hideButtons={latestedGuess === productRRPRoundedPrice} />
				<div className='dollar'></div>
			</div>}
			{(latestedGuess === productRRPRoundedPrice || failed) && <div className='price-container'>
				<div className='win-text animate'>${productRRPRoundedPrice}</div>
			</div>}
			{latestedGuess !== productRRPRoundedPrice && !failed && <div className='check-price-container'>
				<div className={animateLower ? 'price-diff lower animate' : 'price-diff lower'}>{'🔻🔻🔻'.substring(0, animateLower * 2)}</div>
				{/* <div className={'price-diff animate'}>{'🔻🔻🔻'}</div> */}
				<div className={'check-price-button'}><Button onClick={checkPrice}>Guess price!</Button></div>
				<div className={animateHigher ? 'price-diff higher animate' : 'price-diff higher'}>{'🔺🔺🔺'.substring(0, animateHigher * 2)}</div>
				{/* <div className={'price-diff animate'}>{'🔻🔻🔻'}</div> */}
			</div>}
			{latestedGuess !== productRRPRoundedPrice && !failed && <div className='guess-button-subtext'><div>*USD</div></div>}

			{guesses && <div className='guesses-container'>
				<table className='guesses-table'>
					<tbody>
						{guesses.map((g, i) => {
							return <tr key={i}>
								<td className='guess-count'>#{guesses.length - i}</td>
								<td className='guess-code'>{g.codeHint}</td>
								<td>{g.higherLower}</td>
								<td className='guess-text'>${g.guess}</td>
							</tr>
						})}
					</tbody>
				</table>
			</div>}

			{latestedGuess === productRRPRoundedPrice && <div className='check-price-container'><a target="_blank" rel='noreferrer' href={productBuyNowLink}><Button onClick={buyOnAmazon}><div>Buy on Amazon for ${productSalePrice} USD</div></Button></a></div>}
			{latestedGuess === productRRPRoundedPrice && <div className='guess-button-subtext'><div>*as an Amazon Associate I earn from qualifying purchases.</div></div>}

			{(latestedGuess === productRRPRoundedPrice || failed) && <div className='share-container'>
				<ShareButton
					text={getShareText()}
					onShare={(success: boolean) => {
						if (success) {
							logEvent(fbAnalytics, 'share', { method: 'share_score', item_id: productBuyNowLink });
						}
					}}
					onCopy={(success: boolean) => {
						if (success) {
							logEvent(fbAnalytics, 'share', { method: 'share_score', item_id: productBuyNowLink });
							setShareResult('Copied to clipboard');
						} else {
							setShareResult('Copyto clipboard failed');
							setShareText(getShareText());
						}
					}}
				/>
				{shareResult && <div>
					<div className='text-copied'>{shareResult}</div>
					{shareText && <div className='text-backup'>
						{getShareBody()}
					</div>}
				</div>}
			</div >}
		</section >
	)
};
